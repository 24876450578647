import React from 'react'
import { BottomHeader } from './components/BottomHeader'
import { TopHeader } from './components/TopHeader'

export const Header = () => {
	return (
		<header className={'flex flex-col w-full shadow-md'}>
			<TopHeader />
			<BottomHeader />
		</header>
	)
}

import { Link } from 'gatsby'
import React from 'react'
import { routes } from '../../../../lib/routes'
import { Container } from '../../Container/Container'
import { LogoLink } from './LogoLink'
import { MobileMenu } from './MobileMenu'

export type BottomHeaderProps = React.HTMLAttributes<HTMLElement>

const renderNavItems = () =>
	Object.values(routes)
		.filter((route) => route.showInMenu !== false)
		.map((route) => (
			<Link
				key={route.to}
				activeClassName={'text-secondary'}
				className={'text-indigo hover:text-secondary transition duration-200 font-medium uppercase'}
				to={route.to}
			>
				{route.label}
			</Link>
		))

export const BottomHeader: React.FC<BottomHeaderProps> = (props: BottomHeaderProps) => {
	return (
		<div className="py-5">
			<Container className="flex justify-between">
				<LogoLink />

				<div className="hidden nav-items xl:flex justify-end space-x-5 text-primary items-center font-semibold ">
					{renderNavItems()}
				</div>
				<div className="mm xl:hidden flex justify-end items-center">
					<MobileMenu />
				</div>
			</Container>
		</div>
	)
}

export const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  REBECCA_PURPLE: '#663399',
  BLUE: '#1a58cc',
};

export const CONTACT = {
  phone: '048/ 38 11 692',
  worktime: '7:00-15:30',
  workDays: 'Pondelok - Piatok',
  workDaysShort: 'Po - Pia',
  address: 'Janka Kráľa 4A Banská Bystrica'
}

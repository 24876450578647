import { Link } from 'gatsby'
import React from 'react'
import { Button } from '../../Button/Button'
import { MapIframe } from '../../MapIframe/MapIframe'

export interface FooterRightSideProps {
	className?: string
}

export const FooterRightSide = (props: FooterRightSideProps) => {
	return (
		<div className="flex justify-center     shadow-blue2 rounded-br-3xl">
			<MapIframe height="400" className="h-full" /> 
		</div>
	)
}

import React, { ReactNode } from 'react'

export interface ButtonProps {
	className?: string
	children?: ReactNode
}

export const Button = (props: ButtonProps) => {
	const { className = '', children } = props
	return (
		// <div
		// 	className={`text-white bg-primary inline-flex py-3 px-10 rounded-full border-primary hover:text-primary
		// 	hover:bg-white hover:border-primary  border-2 hover:border-2 duration-500 easy-out text-center	 ${className}`}
		// >
		<div
			className={`text-white bg-primary inline-flex py-3 px-10 rounded-full border-primary hover:text-primary 
			hover:bg-white hover:border-transparent  border-2 hover:border-2 duration-500 easy-out text-center	font-semibold uppercase tracking-wider ${className}`}
		>
			{children}
		</div>
	)
}

import React, { ReactNode } from 'react'
import { IoMdPin } from 'react-icons/io'
import { ImPhone } from 'react-icons/im'
import { HiOutlineClock } from 'react-icons/hi'
import { LogoLink } from '../../Header/components/LogoLink'
import { CONTACT } from '../../../../lib/consts'

export interface FooterBannerProps {
	className?: string
}

interface IconWrapperProps {
	children: ReactNode
}

const IconWrapper = (props: IconWrapperProps) => {
	const { children } = props
	return (
		<span className=" bg-primary md:h-14 md:w-14 w-10 h-10 justify-center items-center flex rounded-full ">
			{children}
		</span>
	)
}

export const FooterBanner = (props: FooterBannerProps) => {
	return (
		<div className="bg-white shadow-md rounded-tr-3xl rounded-bl-3xl flex flex-col items-center justify-center py-16 md:px-10 px-6 relative -top-16	 lg:max-w-md  w-11/12 mx-auto max-w-sm md:left-0 md:mr-auto md:ml-auto">
			<div className="w-40 mx-auto">
				<LogoLink />
			</div>
			<p className="text-base text-terciary mt-2 text-center">
				Súkromná očná ambulancia
				<br />v centre Banskej Bystrice
			</p>
			<div>
				<div className="flex space-x-4 mt-7">
					<div className="">
						<IconWrapper>
							<IoMdPin className="text-white md:text-3xl text-xl" />
						</IconWrapper>
					</div>
					<div>
						{/* TODO: link na adresu?  */}
						<p className="text-terciary">Adresa</p>
						<p className="font-bold text-indigo">{CONTACT.address}</p>
					</div>
				</div>

				<div className="flex space-x-4  mt-7">
					<div>
						<IconWrapper>
							<ImPhone className="text-white  md:text-3xl text-xl" />
						</IconWrapper>
					</div>
					<div>
						<p className="text-terciary">Tel. č.</p>
						<p className="font-bold text-indigo">
							<a href={`tel: ${CONTACT.phone}`} target="_blank" rel="noopener noreferrer">
								{CONTACT.phone}
							</a>
						</p>
					</div>
				</div>

				<div className="flex space-x-4  mt-7">
					<div>
						<IconWrapper>
							<HiOutlineClock className="text-white  md:text-3xl text-xl" />
						</IconWrapper>
					</div>
					<div>
						<p className="text-terciary">Ordinačné hodiny</p>
						<p className="font-bold text-indigo">
							{CONTACT.workDaysShort} <span className="text-primary pl-1">{CONTACT.worktime}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

import React from 'react'
import { HiOutlineClock } from 'react-icons/hi'
import { IoMdPin } from 'react-icons/io'
import { CONTACT } from '../../../../lib/consts'
import { Button } from '../../Button/Button'
import { Container } from '../../Container/Container'

export type TopHeaderProps = React.HTMLAttributes<HTMLElement>

export const TopHeader: React.FC<TopHeaderProps> = (props: TopHeaderProps) => {
	return (
		<div className="py-4   border-customGray bg-secondary">
			<Container className="flex justify-end flex-col sm:flex-row items-center">
				<div className="flex space-x-6 flex-row items-center justify-between md:justify-end w-full">
					<div className="flex space-x-2 items-center">
						<HiOutlineClock className="text-lg text-primary md:block hidden" />
						<div className="font-semibold md:text-base text-sm">
							<span className="text-white">{CONTACT.workDaysShort}</span>
							<span className="text-white pl-1">{CONTACT.worktime}</span>
						</div>
					</div>

					{/* //TODO: pridat spravnu adresu s odkazom na google maps */}
					<div className="md:flex space-x-2 items-center hidden">
						<IoMdPin className="text-lg text-primary font-semibold" />
						<span className="text-white font-semibold">
							<a href="https://goo.gl/maps/4GHVS87Z4PtThsMx7" className="hover:opacity-80">
								{CONTACT.address}
							</a>
						</span>
					</div>

					<a href={`tel:${CONTACT.phone}`} target="_blank" rel="noopener noreferrer">
						<div
							className={`text-white bg-primary inline-flex py-1.5 px-4 rounded-full border-primary hover:text-primary 
			hover:bg-white hover:border-white  border-2 hover:border-2 duration-500 easy-out text-center md:text-base  text-sm font-semibold `}
						>
							Zavolať
						</div>
					</a>
				</div>
			</Container>
		</div>
	)
}

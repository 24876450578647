export interface Route {
  label: string;
  to: string;
  showInMenu?: boolean;
}

const asRoute = (route: Route): Route => (route)

export const routes = {
  home: asRoute({
    label: "Domov",
    to: "/"
  }),
  services: asRoute({
    label: "Naše služby",
    to: "/sluzby"
  }),
  about: asRoute({
    label: "O nás",
    to: "/o-nas"
  }),

  contact: asRoute({
    label: "Kontakt",
    to: "/kontakt"
  }),

};
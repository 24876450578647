import React from 'react'

export interface MapIframeProps {
	className?: string
	height: string
}

export const MapIframe = (props: MapIframeProps) => {
	return (
		<iframe
			src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2631.574709060814!2d19.13749611566999!3d48.73271757927539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47153df09218ffd5%3A0xa5006fa8c40e03af!2sJanka%20Kr%C3%A1%C4%BEa%204a%2C%20974%2001%20Bansk%C3%A1%20Bystrica!5e0!3m2!1ssk!2ssk!4v1630674155036!5m2!1ssk!2ssk"
			width="100%"
			height={props.height}
			loading="lazy"
		></iframe>
	)
}

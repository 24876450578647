import React from 'react'
import { routes } from '../../../lib/routes'
import { Container } from '../Container/Container'
import { FooterBanner } from './components/FooterBanner'
import { FooterRightSide } from './components/FooterRightSide'

export interface FooterProps {
	className?: string
}

export const Footer = (props: FooterProps) => {
	const url = typeof window !== 'undefined' ? window.location.pathname : ''
	return (
		<footer className="">
			<section className="bg-lightBlue min-h-96 pb-16">
				<Container className="flex space-x-0 content-center md:justify-center justify-center flex-col md:flex-row items-center relative">
					<div className="md:w-1/2  w-full">
						<FooterBanner />
					</div>
					<div className="md:w-1/2  w-full">
						<FooterRightSide />
					</div>
				</Container>
			</section>

			<section className=" bg-white">
				<Container>
					<p className="text-center pt-6 pb-5 text-sm text-gray-500">
						Stránka vytvorená spoločnosťou{' '}
						<a
							href="https://mmntm.digital/"
							target="_blank"
							rel="noopener noreferrer"
							className="hover:opacity-50 transition delay-150 duration-300 ease-in-out text-gray-700"
						>
							{'Momentum Digital '}
						</a>
						© 2021 pre Danxia, s.r.o.
					</p>
				</Container>
			</section>
		</footer>
	)
}

import { Link } from 'gatsby'
import React from 'react'
import logosvg from '../../../../assets/icons/logo_ocna_ambulancia.svg'
import { routes } from '../../../../lib/routes'

export type LogoLinkProps = React.HTMLAttributes<HTMLElement>

export const LogoLink: React.FC<LogoLinkProps> = (props: LogoLinkProps) => {
	return (
		<Link to={routes.home.to} className="h-auto w-32">
			<img src={logosvg} alt="Logo" className="" />
		</Link>
	)
}
